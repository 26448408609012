import About from "../components/About";
import TechStack from "../components/TechStack/TechStack";

export default function HomePage() {
  return (
    <>
      <TechStack />
      <About />
    </>
  );
}
